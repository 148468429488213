import React from 'react';
import '../assets/styles/WhoWeAre.css';

const WhoWeAre = () => {
    return (
        <section id="whoweare" className="who-we-are">
            <div className="container">
                <h2>Who we are</h2>
                <hr className="divider" />
                <p>
                    Nexus Interactive LLC is a dynamic web development agency specializing in creating innovative, user-centric websites and digital experiences. We collaborate with businesses of all sizes to bring their online presence to life, combining cutting-edge technology with thoughtful design. From custom websites and e-commerce platforms to content management systems and SEO optimization, we deliver scalable, high-performance solutions that drive success. Let us help you build the digital foundation for your brand and engage your audience like never before.
                </p>
                <p>
                    Nexus Interactive isn’t just a service provider—it’s your trusted partner for navigating and succeeding in the ever-changing world of technology.
                </p>
            </div>
        </section>
    );
};

export default WhoWeAre;
